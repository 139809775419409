import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, LoadingService } from 'ngx-kuv-tools';
import { UsuarioPassChangeComponent } from './components/usuario/usuario-pass-change/usuario-pass-change.component';
import { SesionService } from './utils/sesion/sesion.service';
import data from './utils/sesion/routes.json'
import { ProductoService } from './components/producto/producto.service';
import { SucursalService } from './components/sucursal/sucursal.service';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, DoughnutController, LinearScale, LineController, LineElement, PointElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { VersionUpdateComponent } from './utils/version-update/version-update.component';
import { MarkdownService } from 'ngx-markdown';
import { SuscripcionesPagoPendienteComponent } from './components/suscripciones/suscripciones-pago-pendiente/suscripciones-pago-pendiente.component';
import { SuscripcionesInformacionPlanesComponent } from './components/suscripciones/suscripciones-informacion-planes/suscripciones-informacion-planes.component';
import { environment } from 'src/environments/environment';
import { ModalContactoSoporteComponent } from './shared/contacto-soporte/contacto-soporte.component';
import { DateService } from './utils/date/date.service';
import { TestPlanSelectorComponent } from './utils/test-plan-selector/test-plan-selector.component';
import { SuscripcionesService } from './components/suscripciones/suscripciones.service';
import { SentryService } from './utils/sentry/sentry.service';
import { SugerenciaComponent } from './shared/sugerencia/sugerencia.component';
import { EncuestaNpsCreateComponent } from './components/encuesta-nps/encuesta-nps-create/encuesta-nps-create.component';
import { EncuestaNPSService } from './components/encuesta-nps/encuesta-nps.service';
import { AvisoSistemaComponent } from './utils/aviso-sistema/aviso-sistema.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  fechaAlertaVencimientoSuscripcionPrueba: string = '';
  fechaAlertaVencimientoSuscripcion: string = '';
  diasHastaVencimiento: number | null = null;
  DIAS_TRAS_VENCIMIENTO_LIMITE = -5;
  version: string = environment.version;

  routes: any[] = [];
  configRutas: { [index: string]: any } = data;

  index: any = {
    "page": 1,
    "sorted_asc": true,
    "sorted": false,
    "sorted_by": null,
    "filtered": false,
    "filters": [],
    "pageSize": 20
  };
  stockCriticos: Array<any> = [];
  habilitarSuscripciones = environment.habilitarSuscripciones;

  alertas: Array<any> = [];

  constructor(
    public sesion: SesionService,
    private router: Router,
    private loading: LoadingService,
    private alerts: AlertService,
    private modalService: NgbModal,
    private productoService: ProductoService,
    private ngSelectConfig: NgSelectConfig,
    private sucursalService: SucursalService,
    private mdService: MarkdownService,
    private date: DateService,
    private suscripcionesService: SuscripcionesService,
    private sentryService: SentryService,
    private encuestaNPS: EncuestaNPSService,
  ) {
    Chart.register(ChartDataLabels);
    Chart.register(
      DoughnutController,
      ArcElement,
      LinearScale,
      BarController,
      CategoryScale,
      BarElement,
      LineController,
      PointElement,
      LineElement
    );


    this.ngSelectConfig.notFoundText = 'Ningún elemento encontrado.';
    // this.ngSelectConfig.appendTo = 'body';
  }

  asignarRutas(): void {
    this.loading.show();
    this.sesion.getHomeOptions().subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.sesion.routes = res.routes;
        this.sesion.actualizarElementosBuscadorMenu(res.routes);
      }, error: (err: any) => {
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  ngOnInit() {
    this.fechaAlertaVencimientoSuscripcionPrueba = this.date.format(this.date.addTime('+3 days'), 'yyyy-mm-dd HH:ii:ss');
    this.fechaAlertaVencimientoSuscripcion = this.date.format(this.date.addTime('+7 days'), 'yyyy-mm-dd HH:ii:ss');

    // Elimina el concepto del menú lateral
    this.isLogged();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mostrarEncuestaNPS();
    }, 1000);
  }

  obtenerDiasHastaVencimiento(): number {
    if (!this.fechaAlertaVencimientoSuscripcionPrueba) return 0;
    if (this.diasHastaVencimiento != null) return this.diasHastaVencimiento ?? 0;

    this.diasHastaVencimiento = this.date.dateDiffFixed(new Date(), new Date(this.sesion.user.vencimiento_suscripcion), 'days');
    return this.diasHastaVencimiento ?? 0;
  }

  loadDataAfterLogin() {
    this.asignarRutas();
    this.obtenerDiasHastaVencimiento();
    this.cargarVersionUpdate();
    this.getSucursales();
    this.cargarStockCritico();
    if (environment.habilitarSuscripciones && this.sesion.user.rol_id != 0) this.checkPaymentState();
  }

  checkPaymentState() {
    this.loading.show()
    this.sesion.getPaymentState().subscribe({
      next: (res: any) => {
        this.loading.hide();
        if (!res.suscripcion_activa) {
          this.loading.show()
          this.sesion.checkPaymentState().subscribe({
            next: (res: any) => {
              this.loading.hide();
              const modalRef = this.modalService.open(SuscripcionesPagoPendienteComponent, { centered: true, backdrop: 'static' });
              modalRef.result.then((result: any) => {
                if (result.cerrarSesion) {
                  this.logout();
                  return;
                } else {
                  this.checkPaymentState();
                }
              }, (reason: any) => {
                this.checkPaymentState();
              });
            }, error: (err: any) => {
              this.loading.hide();
              if (err.response) this.alerts.addAlert(err.response, 'danger');
            }
          });
        }
      }, error: (err: any) => {
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  mostrarVersion() {
    this.sesion.getLatestVersion().subscribe({
      next: (version: any) => {
        if (!version) return;
        localStorage.setItem('latest-version', version);
        this.sesion.getLatestVersionMd().subscribe({
          next: (resMD: any) => {
            let md = this.mdService.compile(resMD);
            const modalRef = this.modalService.open(VersionUpdateComponent, { size: 'md', scrollable: true, windowClass: 'modal-version-update' });
            modalRef.componentInstance.md = md;
            modalRef.componentInstance.version = version;
            modalRef.result.then((result: any) => {
              location.reload();
            }, (reason: any) => {
              location.reload();
            });
          }, error: (err: any) => { }
        });
      }, error: (err: any) => {
      }
    });
  }

  cargarVersionUpdate(): void {
    let reload = false;
    this.sesion.getLatestVersion().subscribe({
      next: (version: any) => {
        if (!version) return;
        if (localStorage.getItem('latest-version') != version) {
          localStorage.setItem('latest-version', version);
          this.sesion.getLatestVersionMd().subscribe({
            next: (resMD: any) => {
              let md = this.mdService.compile(resMD);
              const modalRef = this.modalService.open(VersionUpdateComponent, { size: 'md', scrollable: true, windowClass: 'modal-version-update' });
              modalRef.componentInstance.md = md;
              modalRef.componentInstance.version = version;
              modalRef.result.then((result: any) => { reload = true; }, (reason: any) => { reload = true; });
            },
            error: (err: any) => { }
          });
        }
      },
      error: (err: any) => {
        this.cargarAviso(reload);
      },
      complete: () => {
        this.cargarAviso(reload);
      }
    });
  }

  cargarAviso(reload: boolean = false): void {
    this.sesion.getAvisoSistema().subscribe({
      next: (version: any) => {
        if (!version) return;
        if (localStorage.getItem('aviso-sistema') != version) {
          this.sesion.getAvisoSistemaMd().subscribe({
            next: (resMD: any) => {
              const modalRef = this.modalService.open(AvisoSistemaComponent, { size: 'md', scrollable: true, windowClass: 'modal-version-update' });
              modalRef.componentInstance.md = resMD;
              modalRef.componentInstance.version = version;
              modalRef.result.then((result: any) => {
                localStorage.setItem('aviso-sistema', version);
              }, (reason: any) => { });
            },
            error: (err: any) => { }
          });
        }
      },
      error: (err: any) => {
        if (reload) location.reload();
      },
      complete: () => {
        if (reload) location.reload();
      }
    });
  }

  changePass(): void {
    let modalRef = this.modalService.open(UsuarioPassChangeComponent, { centered: true });
    modalRef.result.then((result) => { }, (reason) => { });
  }

  verPerfil(): void {
    this.router.navigate(['empresa/settings/profile']);
  }

  goToSettings(): void {
    this.router.navigate(['empresa/settings']);
  }

  verSuscripcion(): void {
    this.router.navigate(['empresa/settings/subscription']);
  }

  logout(): void {
    this.loading.show();
    this.sesion.clearSesion();
    this.loading.hide();
    this.router.navigate(['login']);
    this.sentryService.setUser(null, null);
  }

  searchFn(term: string, item: any) {
    const removeAccents = (str: string) => {
      return str.normalize("NFD").replace(/[̀-ͯ]/g, "");
    }
    let terms = removeAccents(term.toLowerCase()).split(' ');
    let result = true;
    let p = removeAccents(([item.nombre].join(' ')).toLowerCase());
    terms.forEach(t => {
      result = result && p.includes(t);
    });
    return result;
  }

  isLogged(): void {
    if (window.location.pathname === '/terminos') return;
    if (window.location.pathname === '/soporte') return;
    if (window.location.pathname === '/recuperar-password') return;
    if (window.location.pathname.includes('/restablecer')) return;
    if (window.location.pathname.includes('/test-qr')) return;
    if (window.location.pathname.includes('/suscripcion-tos')) return;

    if (this.sesion.token) {
      this.loading.show();
      this.sesion.isLogged().subscribe({
        next: (res) => {
          this.loading.hide();
          this.sentryService.setUser(this.sesion.user.email, this.sesion.user.empresa_id);
          this.loadDataAfterLogin();
        },
        error: (err) => {
          console.error(err);
          if (err.error && err.error.invalid) {
            this.sesion.clearSesion();
            this.router.navigate(['login']);
            this.sentryService.setUser(null, null);
          } else {
            this.sentryService.setUser(this.sesion.user.email, this.sesion.user.empresa_id);
            this.router.navigate(['home']);
          }
          this.loading.hide();
          this.alerts.addAlert("Sesión expirada.", "danger");
        }
      })
    } else {
      this.router.navigate(['login']);
      this.sentryService.setUser(null, null);
    }
  }

  get hideTopInfoBar(): boolean {
    if (this.router.url.includes('/suscripcion-tos')) return true;
    return false;
  }

  get showSidebarAndHeader(): boolean {
    if (this.router.url === '/login') return false;
    if (this.router.url === '/terminos') return false;
    if (this.router.url === '/soporte') return false;
    if (this.router.url === '/recuperar-password') return false;
    if (this.router.url.includes('/restablecer')) return false;
    if (this.router.url.includes('/test-qr')) return false;
    if (this.router.url.includes('/suscripcion-tos')) return false;
    return true;
  }

  verStockCritico(): void {
    this.router.navigate(['producto/stock-critico']);
  }

  getSucursales(): void {
    if (!this.sesion.token) return;
    this.sucursalService.misSucursales().subscribe({
      next: (res) => {
        this.sesion.sucursales = res.sucursales;
        if (this.sesion.sucursales.length > 0) {
          let localSucursalID = this.sesion.loadLocalSucursal();
          if (localSucursalID) {
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == localSucursalID);
            if (localSucursalID != -1) {
              this.sesion.setSucursal(this.sesion.sucursales[this.sesion.sucursalIndex]);
              this.sesion.saveSucursal(this.sesion.sucursal.id);
            } else {
              this.sesion.setSucursal(this.sesion.sucursales[0]);
              this.sesion.saveSucursal(this.sesion.sucursal.id);
            }
            return;
          }
          if (this.sesion.sucursal?.id != this.sesion.sucursales[0].id) {
            localStorage.setItem('sucursal', JSON.stringify(this.sesion.sucursales[0]));
            this.sesion.setSucursal(this.sesion.sucursales[0]);
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
            this.sesion.saveSucursal(this.sesion.sucursal.id);
          } else {
            let sucursal = this.sesion.sucursal;
            this.sesion.sucursalIndex = this.sesion.sucursales.findIndex(s => s.id == sucursal.id);
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  changeScope(ev: any): void {
    if (!ev.target.value) return;
    let s = this.sesion.sucursales[ev.target.value];
    localStorage.setItem('sucursal', JSON.stringify(s));
    this.sesion.setSucursal(s);
    if (this.sesion.sucursal) {
      this.sesion.saveSucursal(this.sesion.sucursal.id);
    }
    let aux = this.router.url;
    this.router.navigate(['loading']);
    setTimeout(() => {
      this.router.navigate([aux]);
    }, 0);
  }

  enviarSugerencia() {
    const modalRef = this.modalService.open(SugerenciaComponent, { size: 'lg', scrollable: true, centered: true });
    modalRef.result.then((result: any) => { }, (reason: any) => { });
  }

  cargarStockCritico(): void {
    this.loading.show();
    this.productoService.stockCritico(this.index).subscribe({
      next: (res: any) => {
        this.loading.hide();
        this.stockCriticos = res.elements;
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        if (err.response) this.alerts.addAlert(err.response, 'danger');
      }
    });
  }

  crearOT(): void {
    this.router.navigate(['/revisiones/create']);
  }

  soporteLubriKuv(): void {
    const modalRef = this.modalService.open(ModalContactoSoporteComponent, { windowClass: 'clear-modal', size: 's', scrollable: true, centered: true });
    modalRef.componentInstance.soloDemo = this.sesion.user.cuenta_prueba;
    modalRef.componentInstance.showTutorialButton = true;
    modalRef.result.then((result: any) => {
    }, (reason: any) => { });
  }

  async realizarPagoPremium() {
    if (!this.sesion.enableSubscriptionUI) return;
    this.loading.show();
    this.suscripcionesService.planActual().subscribe({
      next: (res: any) => {
        this.loading.hide();
        const modalRef = this.modalService.open(SuscripcionesInformacionPlanesComponent, { size: 'xl', scrollable: true, centered: true });
        modalRef.componentInstance.suscripcionPrevia = res.suscripcion;
        modalRef.componentInstance.suscripcionVPPrevia = res.suscripcionVP;
        modalRef.componentInstance.suscripcionDLGPrevia = res.suscripcionDLG;
        modalRef.result.then((result: any) => { }, (reason: any) => { });
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener la información.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }

  cambiarPrueba(): void {
    const modalRef = this.modalService.open(TestPlanSelectorComponent, { size: 'lg', scrollable: true, centered: true });
    modalRef.result.then((result: any) => { }, (reason: any) => { });
  }

  getPlan(): string {
    switch (this.sesion.user?.suscripcion?.plan_id) {
      case 1:
        return 'FILTRO X';
      case 2:
        return 'FILTRO X';
      case 3:
        return 'ACELERA +';
      case 4:
        return 'ACELERA +';
      case 5:
        return 'TURBO';
      case 6:
        return 'TURBO';
      default:
        return '';
    }
  }

  mostrarEncuestaNPS() {
    if (this.encuestaNPS.debeMostrarEncuestaNPS(this.sesion.user)) {
      this.modalService.open(EncuestaNpsCreateComponent, { size: 'xl', scrollable: true, backdrop: 'static', keyboard: false })
    }
  }
}
