<div class="p-2 p-sm-4">
  <div class="d-flex flex-column border p-3 shadow-sm bg-white gap-3" style="border-radius: 10px;">
    <div class="rowx">
      <div class="d-flex justify-content-center fw-bold">
        <h2>Tutoriales</h2>
      </div>
      <div class="row d-flex flex-wrap col-12 col-xl-10 mx-auto" style="margin-top: -24px;">
        <ngb-carousel [showNavigationIndicators]="false" [ngClass]="'carousel-dark'">
          <ng-template ngbSlide *ngFor="let linksSlide of linksCarousel">
            <div class="row d-flex flex-wrap gx-5 col-12 col-xl-10 col-xxl-10 mx-auto my-5" style="margin-top: -24px;">
              <div class="col-12 col-md-6 mb-4 mb-xl-0 col-xl-4 col-xxl-4 mx-auto" *ngFor="let link of linksSlide">
                <div class="card" style="width: 100%;">
                  <a [href]="link.url" target="_blank">
                    <img [src]="link.image" class="card-img-top" [alt]="link.titulo" style="height: 250px">
                  </a>
                  <div class="card-body">
                    <h5 class="card-title">
                      {{link.titulo}}
                    </h5>
                    <p class="card-text">

                    </p>
                    <div class="d-flex justify-content-between">
                      <span class="badge bg-primary fs-6">
                        {{link.duracion}} min
                      </span>
                      <a [href]="link.url" class="fw-bold" target="_blank">
                        Ver video
                        <i class="fa-solid fa-arrow-right-long ms-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div class="d-flex justify-content-center fw-bold mb-3">
        <h2>Módulos</h2>
      </div>
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xxl-4 mb-3" *ngFor="let link of linksModulos">
            <div class="card shadow-sm h-100 d-flex flex-column">
              <div class="card-body d-flex flex-column">
                <h4 class="text-center mb-2">
                  <i [ngClass]="'fas fa-' + link.icon" class="fs-2 me-3 text-primary"></i>
                  {{link.titulo}}
                </h4>
                <div class="p-3 flex-grow-1">
                  {{link.descripcion}}
                </div>
                <div class="d-flex justify-content-center mt-auto">
                  <a [href]="link.route" class="link-custom fs-6 fw-bold">
                    Ir al módulo
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>